<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="ma-5">
          <v-form ref="frmFilter">
            <!-- Style Fix -->
            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Vendor</label
                >
                <v-autocomplete
                  v-model="vendor"
                  :items="vendors"
                  item-text="name"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-5
                  "
                  placeholder="Select a Vendor"
                  :rules="[(v) => !!v || 'Vendor is Required']"
                  outlined
                  single-line
                  height="48"
                  @change="getSeason"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Season</label
                >
                <v-autocomplete
                  return-object
                  v-model="season"
                  :items="seasons"
                  item-text="name"
                  item-value="id"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Season"
                  :rules="[(v) => !!v || 'Season is Required']"
                  outlined
                  single-line
                  height="48"
                  @change="getOffers"
                >
                </v-autocomplete> </v-col
              ><v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>Offer
                  No</label
                >
                <v-autocomplete
                  return-object
                  v-model="offer"
                  :items="offers"
                  item-text="offer_no"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Offer"
                  :rules="[(v) => !!v || 'Offer is Required']"
                  outlined
                  single-line
                  @change="getList"
                  height="48"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>List
                  Name</label
                >
                <v-autocomplete
                  return-object
                  v-model="list"
                  :items="lists"
                  item-text="list_name"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a List"
                  :rules="[(v) => !!v || 'List is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="d-flex justify-end mx-5 mb-2">
              <v-btn
                @click="close"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  py-3
                  px-6
                  mx-2
                "
                >Clear</v-btn
              >

              <v-btn
                @click="searchData"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Search</v-btn
              ></v-col
            >
          </v-row>
        </div>
      </v-card>

      <v-card class="card-shadow border-radius-xl mt-6" v-show="show_table">
        <v-card-text class="card-padding" id="scroll_1">
          <v-data-table
            :headers="headers"
            :items="tableData"
            item-key="id"
            show-select
            mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
            fixed-header
            class="table"
            @toggle-select-all="selectAll"
            v-model="bidData"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Bids</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                  v-show="saveButtonShow"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  @click="save"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  >Submit
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.data-table-select`]="{ item }">
              <v-checkbox
                :value="item"
                v-if="checkVisibility(item)"
                v-model="bidData"
              ></v-checkbox>
            </template>

            <!--  ## ed added to status  -->
            <template v-slot:[`item.bid_one`]="{ item }">
              <div v-if="item.bid.length > 0">
                <v-chip
                  label
                  small
                  :ripple="false"
                  v-if="item.bid[0].bid_status != 'Pending'"
                  color="green lighten-3"
                  >{{
                    `${item.bid[0].bid_value} - ${item.bid[0].bid_status}ed`
                  }}
                </v-chip>

                <v-chip
                  label
                  small
                  :ripple="false"
                  v-else
                  color="lime lighten-4"
                >
                  {{
                    `${item.bid[0].bid_value} - ${item.bid[0].bid_status}`
                  }}</v-chip
                >
              </div>
              <div v-else>
                <label> N/A</label>
              </div>
            </template>
            <template v-slot:[`item.bid_two`]="{ item }">
              <div v-if="item.bid.length > 1">
                <v-chip
                  label
                  small
                  :ripple="false"
                  v-if="item.bid[1].bid_status != 'Pending'"
                  color="green lighten-3"
                  >{{
                    `${item.bid[1].bid_value} - ${item.bid[1].bid_status}ed`
                  }}
                </v-chip>

                <v-chip
                  label
                  small
                  :ripple="false"
                  v-else
                  color="lime lighten-4"
                >
                  {{
                    `${item.bid[1].bid_value} - ${item.bid[1].bid_status}`
                  }}</v-chip
                >
              </div>
              <div v-else>
                <label> N/A</label>
              </div>
            </template>

            <template v-slot:[`item.bid_three`]="{ item }">
              <div v-if="item.bid.length > 2">
                <v-chip
                  label
                  small
                  :ripple="false"
                  v-if="item.bid[2].bid_status != 'Pending'"
                  color="green lighten-3"
                  >{{
                    `${item.bid[2].bid_value} - ${item.bid[2].bid_status}ed`
                  }}
                </v-chip>

                <v-chip
                  label
                  small
                  :ripple="false"
                  v-else
                  color="lime lighten-4"
                >
                  {{
                    `${item.bid[2].bid_value} - ${item.bid[2].bid_status}`
                  }}</v-chip
                >
              </div>
              <div v-else>
                <label> N/A</label>
              </div>
            </template>

            <template v-slot:[`item.bid_value`]="{ item }">
              <div class="mt-5">
                <v-text-field
                  v-model.number="item.bid_value"
                  min="0"
                  v-on="(item.bid_value = item.bid_value || 0)"
                  :rules="[(v) => v >= 0 || 'Min should be above 0']"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    centered-input
                  "
                  dense
                  flat
                  filled
                  solo
                  v-show="checkVisibility(item)"
                  :readonly="item.bid.length == 0 ? false : checkData(item)"
                ></v-text-field>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                v-show="item.bid.length == 0 ? false : checkVisibility(item)"
                @click="edit(item)"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Edit
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col offset-md="5"
              ><v-btn
                v-show="saveButtonShow"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                @click="save"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Submit
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      show_table: false,
      saveButtonShow: false,
      vendors: [],
      vendor: null,
      season: null,
      seasons: [],
      offer: null,
      offers: [],
      list: null,
      lists: [],
      overlay: false,
      tableData: [],
      bidData: [],
      // bidValues: [{ bidOne: "" }],
      editedData: [],
      headers: [
        {
          text: "Lot No",
          value: "lot_no",
          class: "font-weight-bolder opacity-10",
          width: 200,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "font-weight-bolder opacity-10",
        },
        {
          text: "Price Valuation",
          value: "price_valuation",
          class: "font-weight-bolder opacity-10",
        },
        {
          text: "Offer Price",
          value: "offer_price",
          class: "font-weight-bolder opacity-10",
        },
        {
          text: "Bid 1",
          value: "bid_one",
          class: "font-weight-bolder opacity-10",
        },
        {
          text: "Bid 2",
          value: "bid_two",
          class: "font-weight-bolder opacity-10",
        },
        {
          text: "Bid 3",
          value: "bid_three",
          class: "font-weight-bolder opacity-10",
        },

        {
          text: "Next Bid",
          value: "bid_value",
          class: "font-weight-bolder opacity-10",
        },

        {
          text: "",
          value: "actions",
          class: "font-weight-bolder opacity-10",
        },
        // {
        //   text: "",
        //   value: "bid[2]",
        //   class: "font-weight-bolder opacity-10",
        //
        // },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    selectAll(values) {
      this.bidData = values;
    },

    async save() {
      console.log(this.bidData);
      let data = [];

      if (this.bidData.length > 0) {
        this.bidData.forEach((el) => {
          if (this.checkVisibility(el) == true) {
            data.push(el);
          }
        });
        try {
          this.overlay = true;
          await api.postBids(data);
          this.tableData = [];
          this.bidData = [];
          this.show_table = false;
          this.showSuccessAlert("Data Added");
        } catch (error) {
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
        }
      } else {
        this.showWarningAlert("Please select Atleast one row");
      }
    },
    scrollDown() {
      this.$nextTick(() =>
        document
          .getElementById("scroll_1")
          .scrollIntoView({ behavior: "smooth" })
      );
    },

    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    async initialize() {
      try {
        this.overlay = true;
        this.vendors = await api.getVendorsByTypes("PRIVATE");
        console.log(this.vendors);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async getSeason() {
      try {
        this.overlay = true;
        this.seasons = [];
        this.season = null;
        this.offers = [];
        this.offer = null;
        this.lists = [];
        this.list = null;
        this.seasons = await api.getSeason(this.vendor.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async getOffers() {
      try {
        this.overlay = true;
        this.offers = [];
        this.offer = null;
        this.lists = [];
        this.list = null;
        this.offers = await api.getOffers(this.vendor.id, this.season.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async getList() {
      try {
        this.overlay = true;
        this.lists = [];
        this.list = null;
        this.lists = await api.getList(this.offer.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },
    async searchData() {
      this.show_table = false;
      this.saveButtonShow = false;
      if (this.searchValidation()) {
        this.tableData = [];
        this.bidData = [];
        this.overlay = true;
        try {
          //Fill Table list.id id is primary key of private allocation master table
          this.tableData = await api.getChildByMasterId(this.list.id);
          if (this.tableData.length > 0) {
            this.show_table = true;
            this.tableData.sort((a, b) => {
              const lotA = a.lot_no.toUpperCase();
              const lotB = b.lot_no.toUpperCase();
              if (lotA < lotB) {
                return -1;
              }
              if (lotA > lotB) {
                return 1;
              }

              return 0;
            });
          } else {
            this.noDataFoundAlert("No Data Found...!");
          }
        } catch (error) {
        } finally {
          this.overlay = false;
          this.scrollDown();
        }
      }
    },
    edit(item) {
      this.editedData = [];
      this.editedData.push(item);
    },

    checkData(item) {
      let idx = this.editedData.findIndex((el) => el.id == item.id);
      if (idx > -1) return false;
      else return true;
    },
    checkVisibility(item) {
      console.log(item);
      if (item.bid.length == 0) {
        this.saveButtonShow = true;
        return true;
      } else {
        let idx = item.bid.findIndex((el) => {
          return el.last_status == "Reject";
        });
        console.log(idx);
        if (idx > -1 && item.bid.length < 3) {
          this.saveButtonShow = true;
          return true;
        } else {
          return false;
        }
      }
    },

    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        showConfirmButton: false,
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
      });
    },

    showErrorAlert(message) {
      this.$swal({
        showConfirmButton: true,
        title: "Error",
        text: message,
        type: "error",

        icon: "error",
      });
    },

    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    close() {
      this.vendor = null;
      this.season = null;
      this.seasons = [];
      this.offer = null;
      this.offers = [];
      this.list = null;
      this.lists = [];
      this.show_table = false;
      this.tableData = [];

      this.$refs.frmFilter.resetValidation();
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
